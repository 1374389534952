<template>
  <div class="material">
    <div class="material_detail">
      <div class="detail_left">
        <div class="left_img">
          <el-image :src="material.faceImg"></el-image>
        </div>
        <div class="left_tip" @click="goTry(material)" v-if="material.tryRead">
          <el-image class="tip_icon" :src="require('@/assets/img/textmall/book.png')"></el-image>
          <div class="tip_text">立即试读</div>
        </div>
      </div>
      <div class="detail_right">
        <div class="right_top">
          <div class="top_name">{{ material.textbookName }}</div>
          <div class="top_collect" @click="collectClick">
            <el-image class="collect_img" v-if="collect === '0'" :src="require('@/assets/img/textmall/collect.png')" />
            <el-image v-else-if="collect === '1' && typeTwo" class="collect_img"
              :src="require('@/assets/img/textmall/collect_active.png')" />
            <el-image v-else-if="collect === '1' && typeOne" class="collect_img"
              :src="require('@/assets/img/textmall/collect_z.png')" />
            <el-image v-else-if="collect === '1' && typeThree" class="collect_img"
              :src="require('@/assets/img/textmall/collect_g.png')" />
            <el-image v-else-if="collect === '1' && typeFour" class="collect_img"
              :src="require('@/assets/img/textmall/collect_big.png')" />
            <span class="collect">收藏</span>
          </div>
        </div>
        <div class="right_label">
          <div class="label_tip">教材</div>
          <div class="label_tip">{{ material.zhuanyeName }}</div>
          <div class="label_tip">{{ material.bookVersion }}</div>
        </div>
        <div class="right_choose">
          <div class="chosser_item">
            <p>选购数量：</p>
            <el-input-number v-model="ruleForm.num" :min="1" :max="material.inventory" label="描述文字"
              @change="chooseChange(material)"></el-input-number>
          </div>
          <div>库存：剩余{{ material.inventory ?? 0 }}件</div>
        </div>
        <div class="right_weight">
          重量：<span class="weight">{{ (material.weight * ruleForm.num).toFixed(2) }}kg</span>，预估运费 <span class="weight">{{
            freight }}</span> 元
        </div>
        <div class="right_promise">
          承诺：官方正品保障，低价实惠，真正地让利给学员。
        </div>
        <div class="right_serve">
          服务支持：<span class="available">{{
            material.inventory > 0 ? "有货" : "暂无库存"
          }}</span>，今日下单，24小时内发货<span v-if="isMail === 1">，满{{ exemption }}元包邮</span>
        </div>
        <div class="right_price">
          <div class="price">
            <span class="price_item">￥</span>
            {{ material.entrantsPrice ? material.entrantsPrice : 0 }}
          </div>
          <div class="old_price">
            ￥{{
              (material.entrantsPrice + material.entrantsPrice * 0.2).toFixed(2)
            }}
          </div>
          <el-button class="join" v-if="!IsPurchase" @click="addStuShoppingTrolley(false)"
            :disabled="buyDisabled">加入购物车</el-button>
          <el-button v-if="!IsPurchase" @click="addStuShoppingTrolley(true)" class="buy" :class="{
            buy_btn1: typeOne,
            buy_btn2: typeTwo,
            buy_btn3: typeThree,
            buy_btn4: typeFour,
          }" :disabled="buyDisabled">立即购买</el-button>
          <div v-else class="shoppingCart" @click="yuyue(2)">已购买</div>
        </div>
      </div>
    </div>
    <div class="material_centent">
      <div class="centent_left">
        <div class="warpnav">
          <div class="warpnavs">
            <div class="warpnavsCons" :class="navIndex == 1 ? 'on' : ''" @click="navs(1)">
              <span>目录</span>
              <div class="warpnavsCons_line" :class="{
                line1: typeOne,
                line2: typeTwo,
                line3: typeThree,
                line4: typeFour,
              }" v-if="navIndex == 1" />
            </div>
            <div class="warpnavsCons" :class="navIndex == 2 ? 'on' : ''" @click="navs(2)">
              <span>详情</span>
              <div class="warpnavsCons_line" :class="{
                line1: typeOne,
                line2: typeTwo,
                line3: typeThree,
                line4: typeFour,
              }" v-if="navIndex == 2" />
            </div>
            <div class="warpnavsCons" :class="navIndex == 5 ? 'on' : ''" @click="navs(5)">
              <span>评价<span class="appraise" style="font-size: 14px; margin-left: 2px; color: #666">({{ appraise.length
              }})</span></span>
              <div class="warpnavsCons_line" :class="{
                line1: typeOne,
                line2: typeTwo,
                line3: typeThree,
                line4: typeFour,
              }" v-if="navIndex == 5" />
            </div>
          </div>
        </div>
        <!-- 课程详情内容 -->
        <div v-if="navIndex == 1" class="warpCon">
          <div class="apple">
            <div class="appleLeft">
              <div class="appleLefts" v-html="ImgSizeLimit(material.chapterText)" />
            </div>
          </div>
        </div>
        <div v-if="navIndex == 2" class="warpCon">
          <div class="apple">
            <div class="appleLeft">
              <div class="appleLefts" v-html="ImgSizeLimit(material.textBookNote)" />
            </div>
          </div>
        </div>
        <!-- 课程评价 -->
        <div v-if="navIndex == 5" class="warpCon">
          <div v-if="appraise.length > 0">
            <div class="warpCon_item" v-for="(item, index) in appraise" :key="index">
              <div class="item_left">
                <el-image :src="item.stuImg"></el-image>
              </div>
              <div class="item_right">
                <div class="right_title">{{ item.stuName }}</div>
                <div class="right_tip">{{ item.content }}</div>
                <div class="right_img" v-if="item.img">
                  <!-- <el-image
                    v-for="(v, e) in item.img.split(',')"
                    :key="e"
                    class="img"
                    :src="v"
                  ></el-image> -->
                  <el-image v-for="(v, e) in item.img.split(',')" :key="e" class="img" :src="v"
                    :preview-src-list="item.img.split(',')">
                  </el-image>
                </div>
                <div class="right_time">{{ item.addTime }}</div>
              </div>
            </div>
          </div>

          <div class="not_available" v-else>暂无评价</div>
          <!-- <score-evaluation
            v-if="navIndex == 5"
            :type="2"
            :type-id="bookId"
            :type-nmae="material.title"
            :is-purchase="IsPurchase"
            :course-data="material"
          /> -->
        </div>
      </div>
      <div class="centent_right">
        <div class="right_title">推荐教材</div>
        <div class="right_item" v-for="(item, index) in recommendationList" :key="index" @click="toMaterialPage(item)">
          <el-image class="item_img" :src="item.faceImg"></el-image>
          <div class="item_text">
            <div class="title">{{ item.textbookName }}</div>
            <div class="pirce">
              <span class="pirce_red">¥ {{ item.entrantsPrice.toFixed(2) }}</span>
              <!-- <span class="pirce_one"
                >¥
                {{
                  (item.entrantsPrice + item.entrantsPrice * 0.2).toFixed(2)
                }}</span
              > -->
            </div>
          </div>
        </div>
        <!-- <you-love :id="bookId" class="youlove" :ifteacher="true" :type="2" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import {
  selectTextbookPcData,
  getCurSelfBookConfigPc,
  addStuShoppingTrolley,
  getCollectProduct,
  getEvaluateList,
  getCollectProductStatus,
  selectTextbookCount,
  addTextBookOrder,
} from "@/api/mykecheng";
import Vue from "vue";
import chater from "@/components/Know/course/chater.vue";
import { OrderClient } from "@/api/orderClient";
const orderClient = new OrderClient();
import YouLove from "@/components/Know/youLove.vue";
import { getToken, getInfo, getDomain } from "@/api/cookies";
import CourseData from "@/components/Know/course/courseData.vue";
import courseTask from "@/components/Know/course/courseTask.vue";
import scoreEvaluation from "@/components/Know/course/scoreEvaluation.vue";
import { Evaluation } from "@/api/scoreEvaluation";
import { Know } from "@/api/know";
const know = new Know();
import { checkStudyCode } from "@/api/home.js";
import { selectTextbookPcPage } from "@/api/mykecheng";
const evaluation = new Evaluation();
export default {
  components: { chater, YouLove, CourseData, courseTask, scoreEvaluation },
  data() {
    return {
      appraise: [],
      activeName: "first",
      firstShow: true,
      secondShow: false,
      thirdShow: false,
      freight: "",
      collect: "0",
      material: {},
      ruleForm: {
        num: "",
      },
      navIndex: 1,
      yuyues: 2,
      IsPurchase: false, // 章节列表
      bookId: null,
      classId: null,
      href: "",
      average: 0, // 评分
      productLine: null, // 1普通 2精品
      cardCode: null, // 学习卡id
      classProductLine: 0,
      dataShow: false,
      isFreeze: null, // 是否冻结 0 否 1 是
      recommendationList: [],
      priceShow: true,
      // class类型判断
      typeOne: false,
      typeTwo: false,
      typeThree: false,
      typeFour: false,
      exemption: undefined,
      isMail: undefined,
      repertory: undefined,
    };
  },
  computed: {
    buyDisabled() {
      if (this.material.inventory > 0) return false;
      else return true;
    },
  },
  created() {
    this.cardCode =
      this.$route.query.cardCode == "undefined"
        ? null
        : this.$route.query.cardCode;
    this.checkStudyCode();
    this.href = window.location.href;
    this.bookId = this.$route.query.id;

    this.selectTextbookPcData();

    evaluation.getPresentScore(2, this.bookId).then((res) => {
      this.average = Math.round(res.average);
    });
    know.updateCourseAllClicks(2, this.bookId);
    this.getRecommendationList();
    this.getEvaluateList();
    // 动态class判定
    /**
     * entrantsType=2   =>  招商加盟  ——> typeOne
     * entrantsType=3   =>  聚学U、名跃教育   ——> typeTwo
     * entrantsType=5   =>  高校共建   ——> typeThree
     * entrantsType=1   =>  大客户   ——> typeFour
     */
    const res = getDomain();
    if (res.entrantsType === 2) {
      this.typeOne = true;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 3) {
      this.typeOne = false;
      this.typeTwo = true;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 5) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = true;
      this.typeFour = false;
    } else if (res.entrantsType === 1) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = true;
    }
  },
  methods: {
    /* 点击免费试看 */
    toMaterialPage(item) {
      this.$router.push({
        path: `/mall/material`,
        query: {
          id: item.id,
          title: item.textbookName,
          isPurchase: item.isPurchase,
        },
      });
    },
    async getRecommendationList() {
      const res = await selectTextbookPcPage({
        pageSize: 5,
        pageNum: 1,
      });
      res.rows.forEach((item) => {
        if (item.id === this.material.id) {
          return;
        } else {
          this.recommendationList.push(item);
        }
      });
      //  = res.rows;
    },
    // 评价
    async getEvaluateList() {
      const res = await getEvaluateList(this.bookId);
      this.appraise = res.data;
    },
    // 收藏
    async collectClick() {
      if (!localStorage.getItem("userInfo")) {
        Vue.prototype.goLoginView(false);
        // Vue.prototype.goLoginView(false);
        return;
      } else {
        if (this.collect === "0") {
          this.collect = "1";
        } else if (this.collect === "1") {
          this.collect = "0";
        }
        const res = await getCollectProduct({
          type: "14",
          typeId: this.material.id,
          status: this.collect,
          stuId: JSON.parse(localStorage.getItem("userInfo")).id,
        });
        if (res.code === 0) {
          this.$message.success(res.msg);
          this.selectTextbookPcData();
          this.getCollectProductStatus();
        }
        if (res.msg === "收藏成功") {
          this.collect = "1";
        }
        if (res.msg === "取消收藏") {
          this.collect = "0";
        }
      }
    },
    // 查询是否收藏
    async getCollectProductStatus() {
      if (!localStorage.getItem("userInfo")) {
        return;
      } else {
        const res = await getCollectProductStatus({
          type: 14,
          stuId: JSON.parse(localStorage.getItem("userInfo")).id,
          typeId: this.material.id,
        });
        if (res.data === false) {
          this.collect = "0";
        } else if (res.data === true) {
          this.collect = "1";
        }
      }
    },
    /* 验证学习卡 */
    checkStudyCode() {
      if (this.cardCode) {
        const data = {
          cardCode: this.cardCode,
        };
        checkStudyCode(data).then((res) => {
          if (res.code == 0) {
            this.IsPurchase = true;
            this.$store.commit("IS_PURCHASE_CHANGE", true);
          }
        });
      }
    },
    handleClick(val) {
      if (val === "first") {
        this.firstShow = true;
        this.secondShow = false;
        this.thirdShow = false;
      } else if (val === "second") {
        this.firstShow = false;
        this.secondShow = true;
        this.thirdShow = false;
      } else if (val === "third") {
        this.firstShow = false;
        this.secondShow = true;
        this.thirdShow = false;
      }
    },
    // 免费试看
    goTry(item) {
      this.$router.push({
        path: "/mall/sourcePage",
        query: {
          seeNum: item.tryRead,
          names: item.netFilePath,
          id: item.id,
          title: item.textbookName,
          schoolId: this.schoolId,
          type: 2,
          IsPurchase: this.IsPurchase,
        },
      });
    },
    // 教材详情
    async selectTextbookPcData() {
      const res = await selectTextbookPcData(this.bookId);
      if (res.code == 0) {
        this.material = res.data;

        this.repertory = res.data.inventory;
        this.getCurSelfBookConfigPc(this.material);
        this.getCollectProductStatus();
      }
    },
    // 选购数量
    chooseChange(item) {
      if (!this.ruleForm.num) {
        this.ruleForm.num = "1";
      }
      this.getCurSelfBookConfigPc(item);
    },
    // 预估运费
    async getCurSelfBookConfigPc(val) {
      const res = await getCurSelfBookConfigPc(val.weight * this.ruleForm.num);
      if (res.code == 0) {
        this.isMail = res.data?.isExemption;
        this.exemption = res.data?.exemption;
        this.freight = res.data?.configList[0]?.freight ?? 0;
      }
    },
    // 加入购物车
    async addStuShoppingTrolley(go) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (go) {
        const res = await addTextBookOrder([
          {
            id: this.material.id,
            buyCount: this.ruleForm.num,
          },
        ], 0);
        if (res.code === 0) {
          this.goNext(`/mall/orderView?id=${res.data}`);
        } else {
          this.$message.error(res.msg);
        }
      } else {
        const res = await addStuShoppingTrolley({
          productId: this.material.id,
          buyCount: this.ruleForm.num,
        });
        if (res.code == 0) {
          if (this.repertory === 0) {
            this.$message.error("库存不足");
            return;
          } else {
            const shopRes = await selectTextbookCount();
            this.$store.commit("getNum", shopRes.data);
            this.$message.success("加入购物车成功");
            this.repertory -= 1;
          }
        } else if (res.code === 500) {
          this.$message.error(res.msg);
        }
      }
    },
    // 预约按钮
    yuyue(index) {
      this.yuyues = index;
      if (index == 1) {
        const token = getToken();
        if (token) {
          orderClient
            .addOrders(
              this.material.id,
              localStorage.getItem("userId"),
              this.material.title,
              2,
              this.material.title,
              this.material.kind
            )
            .then((res) => {
              if (res.code == 0) {
                /*   this.users = res.msg; */
                this.$router.push({
                  name: "个人中心订单页",
                  params: { orderNumber: res.msg },
                });
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          Vue.prototype.goLoginView(true);
          // this.$router.push({ name: "登录页", params: { hzc: 1 } });
        }
      }
    },
    getLiveData() {
      know.curCourseDocsList(1, 9999, this.bookId, 3, 1).then((res) => {
        if (res.rows.length > 0) {
          this.dataShow = true;
        }
      });
    },
    // 标签页切换
    navs(val) {
      this.navIndex = val;
    },
  },
};
</script>
<style lang="less" scoped>
.material {
  width: 100%;
  padding-bottom: 40px;

  .material_detail {
    width: 1200px;
    // height: 351px;
    background-color: #fff;
    border-radius: 10px;
    margin: 20px auto;
    display: flex;
    padding: 30px;

    .detail_left {
      width: 291px;
      height: 291px;
      background: #f5f7f9;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .left_img {
        width: 201px;
        height: 266px;

        /deep/ .el-image {
          width: 100%;
          height: 100%;
        }
      }

      .left_tip {
        position: absolute;
        width: 94px;
        height: 32px;
        background: #ffb027;
        border-radius: 16px 0px 0px 16px;
        opacity: 1;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .tip_icon {
          width: 12px;
          height: 12px;
          margin-right: 4px;
          margin-bottom: -1px;
        }

        .tip_text {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 1px;
        }
      }
    }

    .detail_right {
      width: 800px;
      // height: 291px;
      margin-left: 50px;

      .right_top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .top_name {
          width: 700px;
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          white-space: normal;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .top_collect {
          width: 50px;
          height: 29px;
          display: flex;
          justify-content: center;
          align-items: center;

          .collect_img {
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }

          .collect {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }

      .right_label {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .label_tip {
          font-size: 12px;
          height: 22px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #818395;
          padding: 2px 11px;
          // line-height: 22px;
          border-radius: 18px 18px 18px 18px;
          border: 1px solid #818395;
          margin-right: 10px;
        }
      }

      .right_choose {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #818395;

        .chosser_item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 20px;

          /deep/ .el-input-number {
            width: 110px;
            height: 30px;
            line-height: 30px;

            .el-input-number__decrease,
            .el-input-number__increase {
              width: 30px;
              height: 29px;
              background-color: transparent;
            }

            .el-input__inner {
              height: 30px !important;
              line-height: 30px !important;
              padding: 0;
            }
          }
        }
      }

      .right_weight {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #818395;
        margin-top: 10px;

        .weight {
          color: #333;
        }
      }

      .right_promise {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #818395;
        margin-top: 10px;
      }

      .right_serve {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #818395;
        margin-top: 10px;

        .available {
          color: #333;
        }
      }

      .right_price {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 36px;

        .price {
          color: #fb2d25;
          font-size: 30px;
          font-weight: bold;

          .price_item {
            font-size: 23px;
            font-weight: 500;
            margin-right: -5px;
          }
        }

        .old_price {
          margin-left: 20px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          text-decoration: line-through;
        }

        .join {
          width: 130px;
          height: 44px;
          background: #ffffff;
          opacity: 1;
          border: 1px solid #666666;
          margin-left: 40px;
          border-radius: 22px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          justify-content: center;
          // border: none;
          align-items: center;
        }

        .buy {
          width: 130px;
          height: 44px;
          opacity: 1;
          margin-left: 20px;
          border-radius: 22px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
        }

        .buy_btn1 {
          background: #ff5d51;
        }

        .buy_btn2 {
          background: #00ab8c;
        }

        .buy_btn3 {
          background: #006de7;
        }

        .buy_btn4 {
          background: #006de7;
        }

        .shoppingCart {
          width: 130px;
          height: 44px;
          background: #ffffff;
          opacity: 1;
          border: 1px solid #666666;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }

  .material_centent {
    width: 1200px;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .centent_left {
      width: 920px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      padding: 10px 20px;

      .warpnav {
        width: 100%;
        margin: 0px auto;
        height: 48px;
        background: #ffffff;
        padding: 0px 10px;
        line-height: 48px;
        border-radius: 10px 10px 0 0;

        // 缩宽
        .warpnavs {
          height: 100%;
          margin: auto;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          // 知识套餐概述
          .warpnavsCons {
            width: auto;
            height: auto;
            font-size: 14px !important;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #666666;
          }

          .on {
            height: 100%;

            span {
              font-weight: bold !important;
              color: #333333 !important;

              .appraise {
                font-weight: 400 !important;
                color: #666 !important;
              }
            }

            .warpnavsCons_line {
              width: 20px;
              height: 4px;
              margin-left: calc(50% - 10px);
              margin-top: -5px;

              border-radius: 2px;
            }

            .line1 {
              background-color: #ff5d51;
            }

            .line2 {
              background-color: #00ab8c;
            }

            .line3 {
              background-color: #1e82f5;
            }

            .line4 {
              background-color: #1061ff;
            }
          }

          // 转换内容 未选中
          .warpnavsCons {
            height: 100%;
            margin-right: 40px;

            span {
              font-size: 14px;
              font-family: Microsoft YaHei-Regular;
              font-weight: 400;
              color: #666666;
              cursor: pointer;
            }

            // .warpnavsCons_line {
            //   width: 20px;
            //   height: 4px;
            //   background-color: #1e82f5;
            //   border-radius: 2px;
            // }
          }
        }
      }

      .warpCon {
        width: 100%;
        margin: 0px auto;
        background: #fff;
        border-radius: 0 0 10px 10px;

        // 缩宽
        .apple {
          width: 900px;
          height: 100%;
          margin: auto;
          display: flex;
          justify-content: start;
          align-items: flex-start;

          .appleLeft {
            width: 750px;
            height: auto;
            display: flex;
            justify-content: start;
            align-items: flex-start;
            background: #ffffff;
            padding: 37px 10px;
            display: flex;

            .appleLefts/deep/img {
              width: 700px;
              height: auto;
              margin-top: 25px;
            }
          }

          .appleRight {
            width: 430px;
            height: 1292px;
            background: #ffffff;

            .appleRightTop {
              width: auto;
              height: 20px;
              margin: 16px 22px;
              border-left: 6px solid #3855f9;
              padding: 0px 12px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7e7e7e;
            }

            img {
              width: 376px;
              height: 209px;
              margin-top: 25px;
              margin-left: 27px;
              background: rgba(0, 0, 0, 0);
            }
          }
        }

        .warpCon_item {
          width: 100%;
          padding: 20px 10px 0;
          display: flex;
          align-items: flex-start;

          .item_left {
            width: 40px;
            height: 40px;
            border-radius: 50%;

            /deep/ .el-image {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .item_right {
            width: calc(100% - 40px);
            margin-left: 10px;
            border-bottom: 1px solid #eeeeee;

            .right_title {
              font-size: 14px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }

            .right_tip {
              font-size: 14px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 400;
              color: #666666;
              margin-top: 8px;
            }

            .right_img {
              margin-top: 13px;

              .img {
                width: 80px;
                height: 80px;
                // background-color: #ff5d51;
                border-radius: 10px 10px 10px 10px;
                opacity: 1;
                margin-right: 10px;
              }
            }

            .right_time {
              font-size: 12px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 400;
              color: #999999;
              margin-top: 10px;
              margin-bottom: 21px;
            }
          }
        }

        .warpCon_item:nth-last-child(1) {
          .item_right {
            border: none;
          }
        }

        .not_available {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 200px 0;
          font-size: 14px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .centent_right {
      width: 260px;
      // height: 462px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      padding: 15px 20px;

      .youlove {
        width: 100%;

        /deep/ .appleRightTop {
          margin: 0;
        }

        /deep/ .appleRightCen {
          height: 0px;
        }
      }

      .right_title {
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }

      .right_item {
        width: 100%;
        height: 80px;
        margin: 20px 0;
        display: flex;
        cursor: pointer;

        .item_img {
          width: 60px;
          height: 80px;

          /deep/ .el-image {
            width: 100%;
            height: 100%;
          }
        }

        .item_text {
          width: calc(100% - 60px);
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 19px;
            white-space: normal;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .pirce {
            .pirce_red {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #fb2d25;
              line-height: 26px;
            }

            .pirce_one {
              font-size: 10px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 26px;
              margin-left: 20px;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.el-image-viewer__wrapper {
  z-index: 9999 !important;
}

.el-image-viewer__close {
  top: 120px !important;
}
</style>
